import React, { useRef, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import GuessButton from "../../../ActionPanel/GuessButton";
import logo from "../../../../Assets/newsie-triumphant-192-removebg.png"; // Tell webpack this JS file uses this image

import LeaderBoard from "../../modals/HamburgerMenu/pages/Default/LeaderBoard";
import IncorrectGuesses from "../../../ActionPanel/GameDetails/IncorrectGuesses";
import Stopwatch from "../../../ActionPanel/GameDetails/Stopwatch";

import "./Winner.css";
import PerformanceUpdate from "./PerformanceUpdate";
import Score from "../../../ActionPanel/GameDetails/Score";
import Confetti from "react-confetti";
const Winner = () => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);

  const ref = useRef(null);
  const pageContainerRef = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight + 30);
      setWidth(ref.current.clientWidth);
    }
    if (pageContainerRef.current) {
      console.log(pageContainerRef.current.getBoundingClientRect());
      setLeft(pageContainerRef.current.getBoundingClientRect().left);
    }
  }, [ref.current, pageContainerRef.current]);

  const selectedNewsie = useSelector(
    (state) => state.gameReducer.selectedNewsie
  );
  const promoUrl = useSelector((state) => state.gameReducer.promoUrl);
  const dailyTopScores = useSelector(
    (state) => state.metaReducer.dailyTopScores
  );

  const timer = useSelector((state) => state.gameReducer.timer[selectedNewsie]);

  const username = useSelector((state) => state.profileReducer.username);
  const incorrectGuesses = useSelector(
    (state) => state.gameReducer.incorrectGuesses[selectedNewsie]
  );
  const { time, timer_active } = useSelector((state) =>
    state.gameReducer.timer && state.gameReducer.timer[selectedNewsie]
      ? state.gameReducer.timer[selectedNewsie]
      : { time: -1, timer_active: false }
  );
  const SCORE = 1000 - time - 10 * incorrectGuesses;

  return (
    <div ref={pageContainerRef} className="winner-modal">
      <div style={{ width: "100%" }} ref={ref}>
        <div className="confetti-container" style={{ left: "100px" }}>
          <Confetti
            width={width - 200}
            height={height}
            opacity={0.4}
            initialVelocityY={5}
            recycle={true}
            gravity={0.05}
          />
        </div>

        <div className="winner-modal-header-text-container">
          <h4>WOO HOO!</h4>
          <div className="username-or-username-cta-container">
            {username ? (
              <div className="username"> {username}</div>
            ) : (
              <div className="set-username-cta">Set your username!</div>
            )}
          </div>
          <h4>You won today's newsie!</h4>
        </div>
        <div className="winner-modal-mr-newsie">
          <img src={logo} alt="Logo" />
        </div>
      </div>

      <div className="completed-game-final-details-container">
        <div className="completed-time-container">
          <Stopwatch />
        </div>
        <IncorrectGuesses incorrectGuesses={incorrectGuesses} />
        <Score score={SCORE} />
      </div>
      <PerformanceUpdate />
      {promoUrl ? (
        <div className="cash-drop-container">
          <a href={promoUrl} onClick={(e) => e.stopPropagation()}>
            💰 Cash drop!
          </a>
          <div>
            If you're the first to find the cash, its yours! Good luck!
          </div>
        </div>
      ) : null}
      <div className="title-description-divider" />
      <LeaderBoard
        leaderBoardTitle="Today's Top Scores"
        leaderBoardData={dailyTopScores}
      />
      <div className="share-button-wrapper">
        <GuessButton
          modal
          gameOver={true}
          guessable={true}
          time={timer?.time}
          score={SCORE}
          incorrectGuesses={incorrectGuesses}
        />
      </div>
    </div>
  );
};

export default Winner;
