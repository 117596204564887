const env = process.env.REACT_APP_ENV;

export const LOCAL_STORAGE_KEYS = { // Redux Stores too
  BROWSER_ID: 'newsie-browser-id',
  SESSION_TOKEN: 'newsie-session-token',
  AUTH: 'auth',
  GAME: 'game',
  MODAL: 'modal',
  PROFILE: 'profile',
  META: 'meta',
  USER_PERFORMANCE: 'user-performance',
  REFERRAL_CODE: 'referralCode'
}
export const COMPLETED_FIRST_NEWSIE = 'COMPLETED_FIRST_NEWSIE';
export const WON_FIRST_NEWSIE = 'WON_FIRST_NEWSIE';
export const TWO_DAY_STREAK = 'TWO_DAY_STREAK';
export const FIVE_DAY_STREAK = 'FIVE_DAY_STREAK';
export const TEN_DAY_STREAK = 'TEN_DAY_STREAK';


export const USER_ACHIEVEMENTS = {
  [COMPLETED_FIRST_NEWSIE]: COMPLETED_FIRST_NEWSIE,
  [WON_FIRST_NEWSIE]: WON_FIRST_NEWSIE,
  [TWO_DAY_STREAK]: TWO_DAY_STREAK,
  [FIVE_DAY_STREAK]: FIVE_DAY_STREAK,
  [TEN_DAY_STREAK]: TEN_DAY_STREAK
};

export const ACHIEVEMENT_ASSETS = {
  [USER_ACHIEVEMENTS.COMPLETED_FIRST_NEWSIE]: {
    id: USER_ACHIEVEMENTS.COMPLETED_FIRST_NEWSIE,
    title: "Completed First Newsie",
  },
  [USER_ACHIEVEMENTS.WON_FIRST_NEWSIE]: {
    id: USER_ACHIEVEMENTS.WON_FIRST_NEWSIE,
    title: "Won First Newsie",
  },
  [USER_ACHIEVEMENTS.TWO_DAY_STREAK]: {
    id: USER_ACHIEVEMENTS.TWO_DAY_STREAK,
    title: "Two Day Streak!",
  },
  [USER_ACHIEVEMENTS.FIVE_DAY_STREAK]: {
    id: USER_ACHIEVEMENTS.FIVE_DAY_STREAK,
    title: "Five Day Streak!",
  },
  [USER_ACHIEVEMENTS.TEN_DAY_STREAK]: {
    id: USER_ACHIEVEMENTS.TEN_DAY_STREAK,
    title: "Ten Day Streak!",
  },
};

const WEB_APP_ENVS = {
  DEV: 'http://localhost:3001',
  STAGING: 'https://3jh6p7pk2p.us-west-2.awsapprunner.com/',
  PROD: 'https://www.newsie.fyi'
};

const ENVS = {
  DEV: 'http://localhost:3001',
  STAGING: 'https://p2pmewdmxq.us-west-2.awsapprunner.com',
  PROD: 'https://dmdbhtya6u.us-west-2.awsapprunner.com'
};

export const NEWSIE_CATEGORIES = [
  'top-newsie',
  'politics',
  'business',
  'world',
  'sports',
  'entertainment',
  'science',
  'uplifting'
];

export const EMOJI_CATEGORY_TITLES = {
  'top-newsie': ['Top Newsie', null],
  'politics': ["Politics", "🇺🇸"], // Politics
  'business': ["Business", "💰"],
  'world': ["World", "🌎"],
  'sports': ["Sports", "🏅"],
  'entertainment': ["Entertainment", "🎞️"],
  'science': ["Tech/Science", "🔬"],
  'uplifting': ["Uplifting", "🙂"]
};

const ERROR_CODES = {
  SERVER_ERROR: '500',
  USER_ERROR: '400'
}

export const AUTH_ERRORS = {
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  SERVER_ERROR: 'SERVER_ERROR',
  INVALID_USER_INPUT_ERROR: 'INVALID_USER_INPUT_ERROR'
}

// dynamically load this shit
const development = {
  REACT_APP_STRIPE_PK:
    "pk_test_51NrVbFIA3ZjbKrzgyhyv51Tu1RrKET7rAlEChKqRDloMbLcRTBfP9L07S2IcLW3A5pTDjTT5xwCUshHu2F1Ixs7K00Ezh7hINV",
  REACT_APP_STRIPE_CUSTOMER_PORTAL:
    "https://billing.stripe.com/p/login/test_8wM5mRbzMfi87G8cMM",
  SERVER_API: ENVS.PROD,
  WEB_APP_URI: WEB_APP_ENVS.PROD,
  ADS_ENABLED: false
};

const staging = {
  REACT_APP_STRIPE_PK:
    "pk_test_51NrVbFIA3ZjbKrzgyhyv51Tu1RrKET7rAlEChKqRDloMbLcRTBfP9L07S2IcLW3A5pTDjTT5xwCUshHu2F1Ixs7K00Ezh7hINV",
  REACT_APP_STRIPE_CUSTOMER_PORTAL:
    "https://billing.stripe.com/p/login/test_8wM5mRbzMfi87G8cMM",
  SERVER_API: ENVS.STAGING,
  WEB_APP_URI: WEB_APP_ENVS.STAGING,
  ADS_ENABLED: false
}

const production = {
  REACT_APP_STRIPE_PK:
    "pk_live_51NrVbFIA3ZjbKrzgjbMvyCa2mAmiWBadqUWY91s1sKXuwLhHx0w5yM3l3bPrbuV3XWc46KriDgHaJ967oSgrtdky00EGQGxRcl",
  REACT_APP_STRIPE_CUSTOMER_PORTAL:
    "https://billing.stripe.com/p/login/8wM02k2Bk1pg0kE8ww",
  SERVER_API: ENVS.PROD,
  WEB_APP_URI: WEB_APP_ENVS.PROD,
  ADS_ENABLED: false

};

const CONFIG_MAP = {
  dev: development,
  staging: staging,
  prod: production
}
export const CONFIG = CONFIG_MAP[env]


const START = 'START';
const PENDING = 'PENDING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';


export const REQUEST_LIFE_CYCLE = {
  START,
  PENDING,
  SUCCESS,
  ERROR
}
