import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Header from "../Header";
import SolvedStoriesContainer from "../StoryComponents/SolvedStoriesContainer";
import GameContainer from "../GameComponents/GameContainer";

import { setAd, setModal } from "../../redux/features/modalSlice";
import {
  getClue,
  removeCorrectlyGuessedStories,
  setFeedbackModalContent,
  setNewStorySolvedFalse
} from "../../redux/features/gameSlice";

import "./AppGameContainer.css";
import FeedbackModal from "../FeedbackModal";
import { MODALS } from "Components/Modal";

const AppGameContainer = (props) => {
  const { selectedNewsie } = props;
  const [showHint, setShowHint] = useState(false);
  // const [newStorySolved, setNewStorySolved] = useState(false);
  const [encourageClue, setEncourageClue] = useState(false);
  const dispatch = useDispatch();
  const newStorySolved = useSelector((state) => state.gameReducer.newStorySolved)
  const solvedStories = useSelector((state) => state.gameReducer.solvedStories)[
    selectedNewsie
  ];
  const activeClue = useSelector(
    (state) =>
      state.gameReducer.activeClue &&
      state.gameReducer.activeClue[selectedNewsie]
  );
  const remainingStories = useSelector((state) => {
    if (
      !state.gameReducer.stories ||
      !state.gameReducer.stories[selectedNewsie]
    ) {
      return 0;
    }
    return Object.keys(state.gameReducer.stories[selectedNewsie]).length;
  });

  const correctGuess = useSelector((state) => state.gameReducer.correctGuess);
  const feedbackModalContent = useSelector(
    (state) => state.gameReducer.feedbackModalContent
  );
  useEffect(() => {
    if (newStorySolved) setTimeout(() => dispatch(setNewStorySolvedFalse()), 6000);
  }, [newStorySolved]);

  useEffect(() => {
    if (feedbackModalContent && feedbackModalContent.style === "ephemeral")
      setTimeout(() => dispatch(setFeedbackModalContent(null)), 2000);
  }, [feedbackModalContent]);

  useEffect(() => {
    dispatch(setAd());
  }, []);

  // Todo create Modal Reducer
  const handleClickHowToPlay = () => {
    dispatch(setModal("HOW_TO_PLAY"));
  };

  const handleClickHamburgerMenu = () => {
    dispatch(setModal("HAMBURGER_MENU"));
  };

  const handleClickNewsieCategories = () => {
    dispatch(setModal("NEWSIE_CATEGORIES"));
  };

  const handleClickClueButton = () => {
    if (!activeClue) dispatch(getClue());
    setEncourageClue(false);
    setShowHint(!showHint);
  };

  useEffect(() => {
    if (correctGuess) {
      setTimeout(() => {
        dispatch(removeCorrectlyGuessedStories());
      }, 1000); // Todo make constant
    }
  }, [correctGuess]);
  const handleAppContainerClick = () => {
    if (feedbackModalContent && feedbackModalContent.style === "persist")
      dispatch(setFeedbackModalContent(null));
  };

  const MemoizedStoriesContainer = useMemo(() => {
    if (solvedStories && solvedStories.length)
      return () => (
        <SolvedStoriesContainer
          remainingStories={remainingStories}
          newStorySolved={newStorySolved}
          solvedStories={solvedStories}
        />
      );
    return () => <></>;
  }, [remainingStories, newStorySolved, solvedStories]);

  const className = `app-game-container`;
  return (
    <div className={className} onClick={handleAppContainerClick}>
      {showHint && (
        <div className="clue-background" onClick={() => setShowHint(false)}>
          <div className="clue-modal-container">
            <div className="clue-modal">{activeClue}</div>
          </div>
        </div>
      )}

      {feedbackModalContent && (
        <FeedbackModal
          text={feedbackModalContent.feedbackModalContent}
          style={feedbackModalContent.style}
          type={feedbackModalContent.type}
        />
      )}

      <Header
        onClickInstructions={handleClickHowToPlay}
        onClickHamburgerMenu={handleClickHamburgerMenu}
        onClickNewsieCategories={handleClickNewsieCategories}
        onClickClueButton={handleClickClueButton}
        encourageClue={encourageClue}
        dontShowClue={!remainingStories || remainingStories.length === 0}
      />

      <MemoizedStoriesContainer />
      <GameContainer remainingStories={remainingStories} />
    </div>
  );
};

export default AppGameContainer;
