import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEYS } from "../../util/constants";

const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const NEWSIE_HEADLINE_WORD_COUNT = 3;
const MAX_STORIES = 7;

const ENCOURAGING_WORDS = [
  "You're absolutely amazing!",
  "You're a true rock star!",
  "You're incredibly talented!",
  "Impressive! Your capabilities are boundless.",
  "You've exceeded all expectations!",
  "Your skills are truly remarkable!",
  "You've proven your exceptional abilities.",
  "Bravo! Your competence knows no bounds.",
  "You are a true master at what you do!",
  "You're a shining example of excellence!",
  "Outstanding! Your capabilities are unmatched.",
  "Your potential is limitless!",
  "You're a force to be reckoned with!",
  "Your abilities are second to none.",
  "You are exceptionally capable!",
];

const OOPS_WORDS = [
  "You've got this! Consider a hint.",
  "Believe in yourself! A hint might help.",
  "Stay positive! Don't hesitate to use a hint.",
  "Keep going! Using a hint is a smart move.",
  "Never give up! A hint can light the way.",
  "You're amazing! Remember, hints are there for you.",
  "You're strong! A hint can be your ally.",
  "Don't be discouraged! Hints are your friends.",
  "You can do it! And a hint can guide you.",
  "Success is near! A hint can lead you there.",
  "Stay determined! Hints can be empowering.",
  "Stay motivated! A hint is a useful tool.",
  "You are resilient! Hints can bolster your efforts.",
  "Think positive thoughts! Don't forget the hints.",
  "You are capable! Hints are at your disposal.",
  "Dream big! Hints can make dreams a reality.",
  "You are unstoppable! With hints, nothing can stand in your way.",
  "Stay focused! Hints can help you stay on track.",
  "Keep pushing forward! A hint can be your guiding light.",
  "Stay hopeful! Hints can brighten your path.",
];

const getRandom = (items) => {
  var item = items[Math.floor(Math.random() * items.length)];
  return item;
};

// Goal of this funciton is to repopulate entire game from new stories
const resetEntireGame = (state) => {
  state.selectedNewsie = "top-newsie";
  state.words = {};
  state.completedLoserDiscovery = {};
  state.gaveUp = {};
  state.winner = {};
  state.gameOver = {};
  state.recordedGameStatus = {};
  state.resultModalShown = {};
  state.stories = {};
  state.activeClue = {};
  state.attempts = {};
  state.feedbackModalContent = null;
  state.timer = {};
  state.incorrectGuesses = {};
  Object.keys(state.allNewsies).forEach((k) => {
    state.stories[k] = {};
    state.gaveUp[k] = false;
    state.solvedStories[k] = [];
    state.words[k] = null;
    state.completedLoserDiscovery[k] = false;
    state.winner[k] = false;
    state.gameOver[k] = false;
    state.recordedGameStatus[k] = false;
    state.resultModalShown[k] = false;
    state.activeClue[k] = null;
    state.timer[k] = { time: 0, timer_active: false };
    state.incorrectGuesses[k] = 0;
    if (state.attempts) state.attempts[k] = 0;
  });
  populateStoriesForSelectedNewsie(state);
};

const populateStoriesForSelectedNewsie = (state) => {
  // if already exists in played games
  if (
    state.words[state.selectedNewsie] ||
    state.gameOver[state.selectedNewsie]
  ) {
    return;
  }
  // Add to at the very least observed newsies
  const stories = state.allNewsies[state.selectedNewsie];
  const allNewsiesInCategory = {};
  stories.forEach((story) => {
    const key = story.newsieHeadline.replaceAll(" ", "");
    const headLineArray = story.newsieHeadline.split(" ");
    story = { ...story, headLineArray };
    allNewsiesInCategory[key] = story;
  });
  state.stories = {
    ...state.stories,
    [state.selectedNewsie]: allNewsiesInCategory,
  };
  const splitHeadlines = stories.map((story) =>
    story.newsieHeadline.split(" ")
  );
  let cards = [[], [], []];
  splitHeadlines.forEach((headline) => {
    if (
      headline.length === NEWSIE_HEADLINE_WORD_COUNT &&
      cards[0].length < MAX_STORIES
    )
      headline.forEach((word, i) => cards[i].push(word));
  });
  cards = cards.map((column) => shuffle(column));
  state.words = { ...state.words, [state.selectedNewsie]: cards };
  state.selectedWords = new Array(cards.length).fill(null);
};

const initialState = {
  selectedWords: null,
  words: false,
  stories: null, // Really Remaining Stories
  activeStory: null,
  solvedStories: {},
  gameOver: false,
  recordedGameStatus: false,
  incorrectGuess: false,
  correctGuess: false,
  winner: false,
  loser: false,
  completedLoserDiscovery: false,
  gaveUp: false,
  feedbackModalContent: null, // RM maybe?
  allNewsies: {},
  selectedNewsie: "top-newsie",
  dataLoaded: false,
  resultModalShown: null,
  lastNewsieUpdateDate: null,
  clearGame: false, // Should clear game
  activeClue: null,
  attempts: false,
  timer: false,
  incorrectGuesses: false,
  newStorySolved: false,
  performanceUpdate: {},
  promoUrl: null
};
export const gameSlice = createSlice({
  name: LOCAL_STORAGE_KEYS.GAME,
  initialState,
  reducers: {

    addLocalStory: (state, action) => {
      // state.words
    },
    completedClearingGame: (state) => {
      state.clearGame = false;
    },
    recordGameStatusSuccess: (state, action) => {
      if (action.payload.promoUrl) {
        state.promoUrl = action.payload.promoUrl;
      } else {
        state.promoUrl = null;
      }
      if (action.payload.performanceUpdate) {
        state.performanceUpdate = action.payload.performanceUpdate;
      } else {
        state.performanceUpdate = {};
      }
      
      state.recordedGameStatus[state.selectedNewsie] = true;
    },
    setLastNewsieUpdateDate: (state, action) => {
      state.lastNewsieUpdateDate = action.payload;
    },
    removeCorrectlyGuessedStories: (state, action) => {
      state.solvedStories = {
        ...state.solvedStories,
        [state.selectedNewsie]: [
          ...state.solvedStories[state.selectedNewsie],
          { ...state.activeStory },
        ],
      };
      state.newStorySolved = true;
      // TODO: Remove guesses words
      let newWords = state.words[state.selectedNewsie].map((wordColumn, i) => {
        wordColumn.splice(state.selectedWords[i], 1);
        return wordColumn;
      });
      if (newWords.every((wordColumn) => wordColumn.length === 0))
        newWords = null;
      state.words = { ...state.words, [state.selectedNewsie]: newWords };
      state.selectedWords = [];
      const rowsRemaining = state.stories[state.selectedNewsie]
        ? Object.keys(state.stories[state.selectedNewsie]).length
        : 0;
      state.correctGuess = false;

      if (!state.words[state.selectedNewsie]) {
        // Winner
        state.winner[state.selectedNewsie] = true;
        state.gameOver[state.selectedNewsie] = true;
        state.timer[state.selectedNewsie] = {
          ...state.timer[state.selectedNewsie],
          timer_active: false,
        };
      }
    },
    setNewStorySolvedFalse: (state) => {
      state.newStorySolved = false;
    },
    guessStory: (state) => {
      state.activeClue[state.selectedNewsie] = null;
      const res = state.selectedWords.map(
        (index, i) => state.words[state.selectedNewsie][i][index]
      );
      const key = res.join("");
      const story = state.stories[state.selectedNewsie][key];
      if (story) {
        // Correct Guess
        if (!state.gameOver[state.selectedNewsie]) {
          state.feedbackModalContent = {
            feedbackModalContent: getRandom(ENCOURAGING_WORDS),
            style: "ephemeral",
            type: "correct-guess",
          };
        }
        state.correctGuess = true;
        state.activeStory = story;
        const oldStories = { ...state.stories[state.selectedNewsie] };
        delete oldStories[key];
        state.stories = {
          ...state.stories,
          [state.selectedNewsie]: { ...oldStories },
        };
      } else {
        // Incorrect Guess
        if (!state.gameOver[state.selectedNewsie]) {
          state.feedbackModalContent = {
            feedbackModalContent: getRandom(OOPS_WORDS),
            style: "ephemeral",
            type: "incorrect-guess",
          };
        }
        state.incorrectGuesses = {
          ...state.incorrectGuesses,
          [state.selectedNewsie]:
            state.incorrectGuesses[state.selectedNewsie] + 1,
        };

        state.incorrectGuess = true;
      }
      state.guessable = false;
    },
    selectWord: (state, action) => {
      state.incorrectGuess = false;
      const payload = action.payload;
      const newSelectedWords = [...state.selectedWords];
      newSelectedWords[payload.columnIndex] = payload.wordIndex;
      state.selectedWords = newSelectedWords;
      if (newSelectedWords.every((i) => Number.isInteger(i))) {
        state.guessable = true;
      } else {
        state.guessable = false;
      }
    },
    setFeedbackModalContent: (state, action) => {
      state.feedbackModalContent = action.payload;
    },
    setStories: (state, action) => {
      const stories = action.payload;
      const allNewsiesInCategory = {};
      stories[state.selectedNewsie].forEach((story) => {
        const key = story.newsieHeadline.replaceAll(" ", "");
        const headLineArray = story.newsieHeadline.split(" ").map((str) => {
          // Check if the string is empty or undefined
          if (!str) return str;

          // Capitalize the first letter and concatenate the rest of the string
          return str.charAt(0).toUpperCase() + str.slice(1);
        });
        story = { ...story, headLineArray };
        allNewsiesInCategory[key] = story;
      });
      state.stories = {
        ...state.stories,
        [state.selectedNewsie]: allNewsiesInCategory,
      };
      const splitHeadlines = stories[state.selectedNewsie].map((story) =>
        story.newsieHeadline.split(" ")
      );
      let cards = [[], [], []];
      splitHeadlines.forEach((headline) => {
        if (
          headline.length === NEWSIE_HEADLINE_WORD_COUNT &&
          cards[0].length < MAX_STORIES
        )
          headline.forEach((word, i) => cards[i].push(word));
      });
      cards = cards.map((column) => shuffle(column));
      state.words = { ...state.words, [state.selectedNewsie]: cards };
      state.selectedWords = new Array(cards.length).fill(null);
      state.dataLoaded = true;
    },
    logoutClearGame: (state) => {
      resetEntireGame(state);
    },
    // Called once per appload
    setAllNewsies: (state, action) => {
      const allNewsies = action.payload;
      state.allNewsies = allNewsies;
      resetEntireGame(state);
    },
    setResultModalShown: (state) => {
      state.resultModalShown[state.selectedNewsie] = true;
    },
    // Called on screen load of each newsie category board
    setNewsie: (state, action) => {
      const newsieType = action.payload;
      state.feedbackModalContent = null;
      state.selectedNewsie = newsieType;

      state.selectedWords = new Array(NEWSIE_HEADLINE_WORD_COUNT).fill(null);
      // if already exists in played games
      if (state.gameOver[newsieType]) {
        console.log("NewsieType");
        return;
      }
      state.timer = {
        ...state.timer,
        [state.selectedNewsie]: {
          ...state.timer[state.selectedNewsie],
          timer_active: true,
        },
      };
      if (state.words[newsieType]) {
        return;
      }
      // Add to at the very least observed newsies
      const stories = state.allNewsies[newsieType];
      const allNewsiesInCategory = {};
      stories.forEach((story) => {
        const key = story.newsieHeadline.replaceAll(" ", "");
        const headLineArray = story.newsieHeadline.split(" ");
        story = { ...story, headLineArray };
        allNewsiesInCategory[key] = story;
      });
      state.stories = {
        ...state.stories,
        [state.selectedNewsie]: allNewsiesInCategory,
      };
      const splitHeadlines = stories.map((story) =>
        story.newsieHeadline.split(" ")
      );
      let cards = [[], [], []];
      splitHeadlines.forEach((headline) => {
        if (
          headline.length === NEWSIE_HEADLINE_WORD_COUNT &&
          cards[0].length < MAX_STORIES
        )
          headline.forEach((word, i) => cards[i].push(word));
      });

      cards = cards.map((column) => shuffle(column));
      state.words = { ...state.words, [state.selectedNewsie]: cards };
    },
    getClue: (state) => {
      const potentialClueCandidates = Object.keys(
        state.stories[state.selectedNewsie]
      );
      const clue =
        state.stories[state.selectedNewsie][
          potentialClueCandidates[
            (potentialClueCandidates.length * Math.random()) << 0
          ]
        ].clue;
      state.activeClue[state.selectedNewsie] = clue;
    },
    giveUp: (state) => {
      state.gaveUp[state.selectedNewsie] = true;
    },
    incrementTimer: (state) => {
      const time = state.timer[state.selectedNewsie].time;
      // console.log(time);
      state.timer[state.selectedNewsie] = {
        ...state.timer[state.selectedNewsie],
        time: time + 1,
      };
    },
    startTimer: (state) => {
      if (state.timer) {
        state.timer[state.selectedNewsie] = {
          ...state.timer[state.selectedNewsie],
          timer_active: true,
        };
      }
    },
    stopTimer: (state) => {
      state.timer[state.selectedNewsie] = {
        ...state.timer[state.selectedNewsie],
        timer_active: true,
      };
    },
    resetActiveNewsie: (state) => {
      const newsieType = state.selectedNewsie;
      // Add to at the very least observed newsies
      const stories = state.allNewsies[newsieType];
      const allNewsiesInCategory = {};
      stories.forEach((story) => {
        const key = story.newsieHeadline.replaceAll(" ", "");
        const headLineArray = story.newsieHeadline.split(" ");
        story = { ...story, headLineArray };
        allNewsiesInCategory[key] = story;
      });
      state.stories = {
        ...state.stories,
        [state.selectedNewsie]: allNewsiesInCategory,
      };
      const splitHeadlines = stories.map((story) =>
        story.newsieHeadline.split(" ")
      );
      let cards = [[], [], []];
      splitHeadlines.forEach((headline) => {
        if (
          headline.length === NEWSIE_HEADLINE_WORD_COUNT &&
          cards[0].length < MAX_STORIES
        )
          headline.forEach((word, i) => cards[i].push(word));
      });
      cards = cards.map((column) => shuffle(column));
      state.words = { ...state.words, [state.selectedNewsie]: cards };
      state.selectedWords = new Array(cards.length).fill(null);
      state.solvedStories[newsieType] = [];
      state.gameOver[newsieType] = false;
      state.completedLoserDiscovery[newsieType] = false;
      state.winner[newsieType] = false;
      state.attempts[newsieType] = state.attempts[newsieType] + 1;
      state.recordedGameStatus[newsieType] = false;
      state.feedbackModalContent = null;
      state.timer[newsieType] = { time: 0, timer_active: false };
    },
  },
});

// Additional logic for saving and loading from localStorage
export const loadGameFromLocalStorage = () => {
  const storedGame = localStorage.getItem(LOCAL_STORAGE_KEYS.GAME);
  return storedGame ? JSON.parse(storedGame) : gameSlice.initialState;
};

export const saveGameToLocalStorage = (game) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.GAME, JSON.stringify(game));
};

// Action creators are generated for each case reducer function
export const {
  giveUp,
  setResultModalShown,
  completedClearingGame,
  setLastNewsieUpdateDate,
  logoutClearGame,
  selectWord,
  setWords,
  setStories,
  guessStory,
  removeCorrectlyGuessedStories,
  setAllNewsies,
  setNewsie,
  getClue,
  resetActiveNewsie,
  recordGameStatusSuccess,
  setFeedbackModalContent,
  incrementTimer,
  startTimer,
  stopTimer,
  setNewStorySolvedFalse,
} = gameSlice.actions;
export default gameSlice.reducer;
