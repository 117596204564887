import React from "react";

import LeaderBoardRow from './LeaderBoardRow';

import "./LeaderBoard.css";

const LeaderBoard = (props) => {
  const { leaderBoardTitle, leaderBoardData } = props;

  return (
    <div className="leader-board-container">
      <div className="leader-board-header-container">
        <div className="leader-board-header-title-container">
          <h3>{leaderBoardTitle}</h3>
        </div>
      </div>
      <div className="leader-board-table-container">
        {leaderBoardData?.map((entry, i) => <LeaderBoardRow index={i} data={entry}/>)}
      </div>
    </div>
  );
};

export default LeaderBoard;
