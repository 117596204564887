import React from 'react';

import './GuessButton.css'

import { useDispatch, useSelector } from 'react-redux';

import { guessStory } from '../../../redux/features/gameSlice'

import { CONFIG } from '../../../util/constants';

const GuessButton = (props) => {
  // Refactor into share and guess button separate
  const dispatch = useDispatch();
  const { guessable, gameOver, incorrectGuesses, time, modal, score } = props;
  const referralCode = useSelector(state => state.profileReducer.referralCode);
  const className = `guess-button ${guessable || gameOver ? 'guessable' : ''} ${modal ? 'modal' : ''}`;
  const minutes = Math.floor(time / 60);
  const seconds = time % 60 < 10 ? `0${time % 60}` : time % 60;
  const url = CONFIG.WEB_APP_URI + (referralCode) ?  `?referralCode=${referralCode}`: '';

  console.log({url})
  const handleGuessClick = () => {
    if (gameOver) {
      if (navigator.share) {
        navigator.share({
          title: 'Newsie: News Puzzle',
          text: `I solved today\'s Newsie with a score of ${score}`+ "\n" + `in: ${minutes}:${seconds} with ${incorrectGuesses} incorrect guesses`,
          url
        }).then(() => {
          console.log('Thanks for sharing!');
        }).catch(console.error);
      } else {
        console.log('NO NAVI')
      }
    }
    else if (guessable) {
      dispatch(guessStory());
    }
  };
  return (<button className={className} onClick={handleGuessClick}>{gameOver ? 'Share' : 'Guess'}</button>)
};

export default GuessButton;